import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { ref, computed } from '@vue/composition-api'
import rolesQuery from '@/@core/queries/roles'
// import privilegesQuery from '@/@core/queries/privileges'
import workersQuery from '@/@core/queries/workers'
import locationsQuery from '@/@core/queries/locations'
import { _getProcesses } from '@/@core/queries/process'
import { getMotives } from "@core/queries/motive";
import { getCrimes } from "@core/queries/crimes";
import { getMetadata } from "@core/queries/metadata";
import { queryEvents, addEvent } from '@/@core/queries/calendar'
import { addCommitment, updateCommitment, getCommitments } from '@/@core/queries/commitments'
import { updateConfirmation } from '@/@core/queries/confirmations'
import { addImprovement, getImprovementNotes } from '@/@core/queries/improvements'
import i18n from '@/libs/i18n'
import axios from "@axios";
import { get, set, getMany, update } from 'idb-keyval';
import awsConnection from '@/views/habit/aws';
import useCommonTodo from '@/views/apps/todo/useCommonTodo'
import endpoints from '@/libs/endpoints'

export default function useCommon() {
  const { role, worker_id, client, locations: userLocations } = store.state?.userStore?.userData
  const userLocationsQuery = userLocations?.map(e => ({ _id: e.value }))
  const { commitment_functionality } = JSON.parse(localStorage.getItem("clientData") || '{}')
  const { showErrorMessage } = useNotifications()
  const { singleUpload, getFile } = awsConnection()
  const { updateImprovementWithKey, sendImprovementEmail, uploadImprovementFile } = useCommonTodo()

  const varGeneral = {
    query: {
      client_id: { _id: client?.$oid },
      deleted_ne: true,
    }
  }
  const varGeneralExtended = {
    query: {
      client_id: { _id: client?.$oid },
      deleted_ne: true,
      onlyDropdownOption_ne: true
    }
  }
  const varWorkerSupervised = {
    query: {
      client_id: { _id: client?.$oid },
      deleted_ne: true,
      onlyDropdownOption_ne: true
    }
  }
  const varSupervisor = {
    query: {
      deleted_ne: true,
      privileges_ne: 'employee',
      client_id: { _id: client?.$oid },
      onlyDropdownOption_ne: true
    }
  }

  if (role === 'supervisor') {
    varWorkerSupervised.query.supervisors_in = {_id: worker_id.$oid}
    delete varSupervisor.query.privileges_ne
    varSupervisor.query.privileges = 'supervisor'
  }

  const roles = ref([])
  const workersTotal = ref([])
  const workersTotalNotMapped = ref([])
  const workersSupervised = ref([])
  const workersSupervisedNotMapped = ref([])
  const supervisors = ref([])
  const supervisorsOnlyNames = ref([])
  const locations = ref([])
  const crimes = ref([])
  const workerPrivileges = ref([])
  const privileges = ref([])
  const processes = ref([])
  const motives = ref([])
  const metadata = ref([])
  const metadataNotMapped = ref([])
  const metadataFilterOptions = ref({zone: [], agency: []})
  const metadataTypeSelect = ref([])
  const metadataTypeSelectNotMapped = ref({})
  const improvements = ref([])
  const commitments = ref([])

  // Main IndexedDB/LocalStorage data keys that are essential for offline mode
  const storageDataKeys = [ "events", "locations", "processesComplete", "workersSupervised" ]

  const checkStorageData = () => {
    getMany(storageDataKeys)
      .then(values => {
        for (const value of values) {
          if (!value) {
            for (const key of storageDataKeys) {
              if (!localStorage[key]) {
                showErrorMessage(i18n.t('message.storage_data_not_detected'), false)
                break
              }
            }
            break
          }
        }
      })
  }

  const updateStorageData = () => {
    fetchAndStoreEvents()
    getRolesForDropDown()
    getWorkersTotalForDropDown("notMapped")
    getWorkersSupervisedForDropDown()
    getWorkersSupervisedForDropDown("notMapped")
    getSupervisorsForDropDown()
    getSupervisorsForDropDown("onlyNames")
    getLocationsForDropDown()
    getProcessesForDropDown()
    getMetadataForDropDown({ category: "improvement", option: "notMapped" })
    getMetadataForDropDown({ type: "select", option: "notMapped" })
    getMotivesForDropDown()
  }

  const storeDataToUpload = (storageProperty, payload) => {
    update(storageProperty, response => {
      if (response) {
        response.push({...payload})
        return response
      } else {
        return [{...payload}]
      }
    })
  }

  const handleError = ({error, defaultMessage, isStorageError}) => {
    if (isStorageError) return showErrorMessage(i18n.t('message.storage_data_fetch_error'))
    if (typeof error === 'string' && error.startsWith("timeout") || error?.message?.startsWith("timeout")) {
      return showErrorMessage(i18n.t('message.internet_connection_unstable'))
    }
    return showErrorMessage(defaultMessage)
  }

  const formatDate = (date, hour = 12) => {
    let formattedDate
    if (date instanceof Date) {
      formattedDate = new Date(date)
      formattedDate.setHours(hour, 0, 0, 0)
    } else {
      formattedDate = new Date(`${date} ${hour}:00:00`)
    }
    return formattedDate
  }

  const getRolesForDropDown = () => {
    get('roles').then(response => {
      if (response) roles.value = response
      else if (localStorage.roles) roles.value = JSON.parse(localStorage.roles)
    })
    
    store
      .dispatch('roleStoreModule/getRolesForDropDown', { query: rolesQuery, variables: { ...varGeneral, limit: 20000, sortBy: "NAME_ASC" } })
      .then(response => {
        if (response.data.errors) throw new Error(response.data.errors[0].message)
        roles.value = response.data?.data?.roles?.map(({ name, _id }) => ({ title: name, value: _id })) || []
        set('roles', roles.value).then(() => {}).catch(error => console.log(error))
      })
      .catch((error) => {
        console.log(error)
        get('roles').then(response => {
          if (!response && !localStorage.roles) handleError({ isStorageError: true })
        })
      })
  }

  const getWorkersTotalForDropDown = (option) => {
    if (option === "notMapped") {
      get('workersTotalNotMapped').then(response => {
        if (response) workersTotalNotMapped.value = response
        else if (localStorage.workersTotalNotMapped) workersTotalNotMapped.value = JSON.parse(localStorage.workersTotalNotMapped)
      })
    } else {
      get('workersTotal').then(response => {
        if (response) workersTotal.value = response
        else if (localStorage.workersTotal) workersTotal.value = JSON.parse(localStorage.workersTotal)
      })
    }
    store
      .dispatch('workerStoreModule/getWorkersTotalForDropDown', { query: workersQuery, variables: { ...varGeneralExtended, limit: 20000, sortBy: "NAME_ASC" } })
      .then(response => {
        if (response.data.errors) throw new Error(response.data.errors[0].message)
        if (option === "notMapped") {
          workersTotalNotMapped.value = response.data?.data?.workers || []
          set('workersTotalNotMapped', workersTotalNotMapped.value).then(() => {}).catch(error => console.log(error))
        } else {
          workersTotal.value = response.data?.data?.workers?.map(({ name, _id }) => ({ title: name, value: _id })) || []
          set('workersTotal', workersTotal.value).then(() => {}).catch(error => console.log(error))
        }
      })
      .catch((error) => {
        console.log(error)
        if (option === "notMapped") {
          get('workersTotalNotMapped').then(response => {
            if (!response && !localStorage.workersTotalNotMapped) handleError({ isStorageError: true })
          })
        } else {
          get('workersTotal').then(response => {
            if (!response && !localStorage.workersTotal) handleError({ isStorageError: true })
          })
        }
      })
  }

  const getWorkersSupervisedForDropDown = (option) => {
    if (option === "notMapped") {
      get('workersSupervisedNotMapped').then(response => {
        if (response) workersSupervisedNotMapped.value = response
        else if (localStorage.workersSupervisedNotMapped) workersSupervisedNotMapped.value = JSON.parse(localStorage.workersSupervisedNotMapped)
      })
    } else {
      get('workersSupervised').then(response => {
        if (response) workersSupervised.value = response
        else if (localStorage.workersSupervised) workersSupervised.value = JSON.parse(localStorage.workersSupervised)
      })
    }
    store
      .dispatch('workerStoreModule/getWorkersSupervisedForDropDown', { query: workersQuery, variables: { ...varWorkerSupervised, limit: 20000, sortBy: "NAME_ASC" } })
      .then(response => {
        if (response.data.errors) throw new Error(response.data.errors[0].message)
        if (option === "notMapped") {
          workersSupervisedNotMapped.value = response.data?.data?.workers || []
          set('workersSupervisedNotMapped', workersSupervisedNotMapped.value).then(() => {}).catch(error => console.log(error))
        } else {
          workersSupervised.value = response.data?.data?.workers?.map(({ name, _id }) => ({ title: name, value: _id })) || []
          set('workersSupervised', workersSupervised.value).then(() => {}).catch(error => console.log(error))
        }
      })
      .catch((error) => {
        console.log(error)
        if (option === "notMapped") {
          get('workersSupervisedNotMapped').then(response => {
            if (!response && !localStorage.workersSupervisedNotMapped) handleError({ isStorageError: true })
          })
        } else {
          get('workersSupervised').then(response => {
            if (!response && !localStorage.workersSupervised) handleError({ isStorageError: true })
          })
        }
      })
  }

  const getSupervisorsForDropDown = (option) => {
    if (option === "onlyNames") {
      get('supervisorsOnlyNames').then(response => {
        if (response) supervisorsOnlyNames.value = response
        else if (localStorage.supervisorsOnlyNames) supervisorsOnlyNames.value = JSON.parse(localStorage.supervisorsOnlyNames)
      })
    } else {
      get('supervisors').then(response => {
        if (response) supervisors.value = response
        else if (localStorage.supervisors) supervisors.value = JSON.parse(localStorage.supervisors)
      })
    }
    store
      .dispatch('workerStoreModule/getSupervisorsForDropDown', { query: workersQuery, variables: { ...varSupervisor, limit: 20000, sortBy: "NAME_ASC" } })
      .then(response => {
        if (response.data.errors) throw new Error(response.data.errors[0].message)
        if (option === "onlyNames") {
          supervisorsOnlyNames.value = Array.from(new Set(response.data?.data?.workers?.map(({ name }) => name) || []))
          set('supervisorsOnlyNames', supervisorsOnlyNames.value).then(() => {}).catch(error => console.log(error))
        } else {
          supervisors.value = response.data?.data?.workers?.map(({ name, _id, locations }) => ({ title: name, value: _id, locationIds: locations.map(e => e._id), locationNames: locations.map(e => e.location) })) || []
          set('supervisors', supervisors.value).then(() => {}).catch(error => console.log(error))
        }
      })
      .catch((error) => {
        console.log(error)
        if (option === "onlyNames") {
          get('supervisorsOnlyNames').then(response => {
            if (!response && !localStorage.supervisorsOnlyNames) handleError({ isStorageError: true })
          })
        } else {
          get('supervisors').then(response => {
            if (!response && !localStorage.supervisors) handleError({ isStorageError: true })
          })
        }
      })
  }

  const getLocationsForDropDown = () => {
    get('locations').then(response => {
      if (response) locations.value = response
      else if (localStorage.locations) locations.value = JSON.parse(localStorage.locations)
    })
    store
      .dispatch('workerStoreModule/getLocationsForDropDown', { query: locationsQuery, variables: { ...varGeneralExtended, limit: 20000, sortBy: "LOCATION_ASC" } })
      .then(response => {
        if (response.data.errors) throw new Error(response.data.errors[0].message)
        locations.value = response.data?.data?.locations?.map(({ location, _id }) => ({ title: location, value: _id })) || []
        set('locations', locations.value).then(() => {}).catch(error => console.log(error))
      })
      .catch((error) => {
        console.log(error)
        get('locations').then(response => {
          if (!response && !localStorage.locations) handleError({ isStorageError: true })
        })
      })
  }

  const getImprovementsForDropDown = () => {
    get('improvements').then(response => {
      if (response) improvements.value = response
    })
    store
      .dispatch('workerStoreModule/getLocationsForDropDown', { query: getImprovementNotes, variables: { ...varGeneral, limit: 20000, sortBy: "NOTE_ASC" } })
      .then(response => {
        if (response.data.errors) throw new Error(response.data.errors[0].message)
        improvements.value = response.data?.data?.improvements?.map(({ note, _id }) => ({ title: note, value: _id })) || []
        set('improvements', improvements.value).then(() => {}).catch(error => console.log(error))
      })
      .catch((error) => {
        console.log(error)
        get('improvements').then(response => {
          if (!response) handleError({ isStorageError: true })
        })
      })
  }

  const getCommitmentsForDropDown = () => {
    get('commitments').then(response => {
      if (response) commitments.value = response
    })
    store
      .dispatch('workerStoreModule/getLocationsForDropDown', { query: getCommitments, variables: { ...varGeneral, limit: 20000, sortBy: "TITLE_ASC" } })
      .then(response => {
        if (response.data.errors) throw new Error(response.data.errors[0].message)
        commitments.value = response.data?.data?.commitments?.map(({ title, _id }) => ({ title, value: _id })) || []
        set('commitments', commitments.value).then(() => {}).catch(error => console.log(error))
      })
      .catch((error) => {
        console.log(error)
        get('commitments').then(response => {
          if (!response) handleError({ isStorageError: true })
        })
      })
  }

  const getProcessesForDropDown = () => {
    get('processesDropdown').then(response => {
      if (response) processes.value = response
      else if (localStorage.processesDropdown) processes.value = JSON.parse(localStorage.processesDropdown)
    })
    store
      .dispatch('processStoreModule/show', {
        query: _getProcesses,
        variables: {
          input: {
            limit: 10000,
            sortBy: 'name',
            client_id: (role !== 'admin') ? client.$oid : null,
            worker_id: (role !== 'admin') ? worker_id.$oid : null,
            role: role,
          }
        }
      })
      .then(response => {
        if (response.data.errors) throw new Error(response.data.errors[0].message)
        const { ProcessesData: { processes: processesResponse } } = response.data.data || {}
        processes.value = processesResponse.map(e => {
          if (e.metadataData) {
            // This variable cannot come as 'metadata' from the backend because it interferes with the process schema validation
            e.metadata = e.metadataData
            delete e.metadataData
          }
          return { title: e.name, value: e._id }
        }) || []
        set('processesComplete', processesResponse).then(() => {}).catch(error => console.log(error))
        set('processesDropdown', processes.value).then(() => {}).catch(error => console.log(error))
      })
      .catch((error) => {
        console.log(error)
        get('processesDropdown').then(response => {
          if (!response && !localStorage.processesDropdown) handleError({ isStorageError: true })
        })
      })
  }

  const getMotivesForDropDown = () => {
    get('motives').then(response => {
      if (response) motives.value = response
      else if (localStorage.motives) motives.value = JSON.parse(localStorage.motives)
    })
    axios
      .post("/graphql", {
        query: getMotives,
        variables: { ...varGeneral, limit: 20000, sortBy: "LABEL_ASC" },
      })
      .then(response => {
        if (response.data.errors) throw new Error(response.data.errors[0].message)
        motives.value = response.data?.data?.motives
        set('motives', motives.value).then(() => {}).catch(error => console.log(error))
      })
      .catch((error) => {
        console.log(error)
        get('motives').then(response => {
          if (!response && !localStorage.motives) handleError({ isStorageError: true })
        })
      })
  }
  
  const getMetadataForDropDown = ({category, option, type}) => {
    if (category === "filter") {
      get('metadataFilterOptions').then(response => {
        if (response) {
          response.forEach(e => {
            Object.assign(metadataFilterOptions.value, {[e.name]: e.options})
          })
        } else if (localStorage.metadataFilterOptions) {
          JSON.parse(localStorage.metadataFilterOptions).forEach(e => {
            Object.assign(metadataFilterOptions.value, {[e.name]: e.options})
          })
        }
      })
    }
    else if (option === "notMapped") {
      if (type === "select") {
        get('metadataTypeSelectNotMapped').then(response => {
          if (response) metadataTypeSelectNotMapped.value = response
          else if (localStorage.metadataTypeSelectNotMapped) metadataTypeSelectNotMapped.value = JSON.parse(localStorage.metadataTypeSelectNotMapped)
        })
      } else {
        get('metadataNotMapped').then(response => {
          if (response) metadataNotMapped.value = response
          else if (localStorage.metadataNotMapped) metadataNotMapped.value = JSON.parse(localStorage.metadataNotMapped)
        })
      }
    }
    else if (type === "select") {
      get('metadataTypeSelect').then(response => {
        if (response) metadataTypeSelect.value = response
        else if (localStorage.metadataTypeSelect) metadataTypeSelect.value = JSON.parse(localStorage.metadataTypeSelect)
      })
    }
    else {
      get('metadata').then(response => {
        if (response) metadata.value = response
        else if (localStorage.metadata) metadata.value = JSON.parse(localStorage.metadata)
      })
    }

    let conditions = {
      client_id: { _id: client.$oid },
      OR: [{ deleted: false }, { deleted_exists: false }],
    }
    if (category) conditions.categories_in = category
    if (type) conditions.type = type

    axios
      .post("/graphql", {
        query: getMetadata,
        variables: { query: conditions, limit: 20000, sortBy: "NAME_ASC" },
      })
      .then(response => {
        if (response.data.errors) throw new Error(response.data.errors[0].message)
        const metadataResponse = response.data?.data?.metadata
        if (category === "filter") {
          metadataResponse.forEach(e => {
            Object.assign(metadataFilterOptions.value, {[e.name]: e.options})
          })
          set('metadataFilterOptions', metadataResponse).then(() => {}).catch(error => console.log(error))
        }
        else if (option === "notMapped") {
          if (type === "select") {
            metadataResponse.forEach(e => {
              Object.assign(metadataTypeSelectNotMapped.value, {[e.name]: e.options})
            })
            set('metadataTypeSelectNotMapped', metadataTypeSelectNotMapped.value).then(() => {}).catch(error => console.log(error))
          } else {
            metadataNotMapped.value = metadataResponse || []
            set('metadataNotMapped', metadataResponse).then(() => {}).catch(error => console.log(error))
          }
        }
        else {
          const metadataResponseMapped = computed(() => {
            return metadataResponse?.map(({ name, _id }) => ({ title: i18n.t(`metadata.${name}`), value: _id }))
          })
          if (type === "select") {
            metadataTypeSelect.value = metadataResponseMapped
            set('metadataTypeSelect', metadataResponseMapped).then(() => {}).catch(error => console.log(error))
          }
          else {
            metadata.value = metadataResponseMapped
            set('metadata', metadataResponseMapped).then(() => {}).catch(error => console.log(error))
          }
        }
      })
      .catch((error) => {
        console.log(error)
        if (category === "filter") {
          get('metadataFilterOptions').then(response => {
            if (!response && !localStorage.metadataFilterOptions) handleError({ isStorageError: true })
          })
        }
        else if (option === "notMapped") {
          if (type === "select") {
            get('metadataTypeSelectNotMapped').then(response => {
              if (!response && !localStorage.metadataTypeSelectNotMapped) handleError({ isStorageError: true })
            })
          }
          get('metadataNotMapped').then(response => {
            if (!response && !localStorage.metadataNotMapped) handleError({ isStorageError: true })
          })
        }
        else if (type === "select") {
          get('metadataTypeSelect').then(response => {
            if (!response && !localStorage.metadataTypeSelect) handleError({ isStorageError: true })
          })
        }
        else {
          get('metadata').then(response => {
            if (!response && !localStorage.metadata) handleError({ isStorageError: true })
          })
        }
      })
  }

  const fetchAndStoreEvents = () => {
    const calendars = ["Pendientes", "Vencidas"]

    const now = new Date()
    const firstDayOfPreviousMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1)
    const firstDayOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1)

    const query = {
      client_id: (role !== 'admin') ? { _id: client.$oid} : null,
      extendedProps: { calendar_in: calendars },
      //Set query to fetch only the events of the previous and current month
      start_gte: firstDayOfPreviousMonth,
      start_lt: firstDayOfNextMonth,
      deleted_ne: true,
      OR: [
        { attendee: { OR: [{ deleted: false }, { deleted_exists: false }] } },
        { attendee_exists: false }
      ],
    }

    if (role === 'supervisor') {
      query.OR[0].attendee.supervisors_in = {_id: worker_id.$oid}
      query.OR[1].organizer = {locations_in: userLocationsQuery}
      query.OR[2] = {attendee: {_id: worker_id.$oid}}
    }

    // eslint-disable-next-line import/no-named-as-default-member
    axios
      .post('/graphql', {
        query: queryEvents,
        variables: {
          query,
          limit: 10000,
        },
      })
      .then((response) => {
        if (response.data.errors) throw new Error(response.data.errors[0].message)
        const events = response.data?.data?.events
        set('events', events).then(() => {}).catch(error => console.log(error))
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const updateConfirmationWithKey = (confirmationId, key) => {
    axios
      .post('/graphql', {
        query: updateConfirmation,
        variables: {
          query: { _id: confirmationId },
          data: {
            imageKey: key,
          },
        },
      })
      .then(({data}) => {
        if (data.errors) throw new Error(data.errors[0].message)
      })
      .catch((error) => {
        console.log(error)
        showErrorMessage(i18n.t('message.confirmation_update_error'))
      })
  }

  const createImprovements = async (improvements, origin, originId) => {
    if (!improvements?.length) return

    const improvementCreationPromises = improvements.map(i => {
      const newImprovement = {
        client_id: i.client_id,
        assignee: i.assignee ? { link: i.assignee._id } : null,
        note: i.note,
        tags: i.tags,
        description: i.description,
        dueDate: i.dueDate ? new Date(`${i.dueDate.slice(0, 10)} 12:00:00`) : null,
        important: i.important,
        origin,
        origin_id: originId,
      }
      if (i.metadata?.length) {
        newImprovement.metadata = i.metadata.map(m => ({
          name: m.name,
          type: m.type,
          answer: m.answer,
        }))
      }
      // if (i.associatedCase) {
      //   newImprovement.associatedCase = { link: i.associatedCase }
      // }

      return new Promise((resolve, reject) => {
        axios
          .post('/graphql', {
            query: addImprovement,
            variables: { data: newImprovement }
          })
          .then(({data}) => {
            if (data.errors) throw new Error(data.errors[0].message)
            const improvementId = data.data.insertOneImprovement._id

            if (i.documentFile?.name) {
              const destinationFolder = `${client.$oid}/documents`
              singleUpload(i.documentFile, destinationFolder)
                .then((key) => updateImprovementWithKey(improvementId, key))
                .catch((err) => console.log(err))
            }

            if (i.assignee?.email) sendImprovementEmail(i)

            resolve(improvementId)
          })
          .catch((error) => {
            console.log(error)
            showErrorMessage(commitment_functionality ? i18n.t('message.commitment_error') : i18n.t('message.improvement_error'))
            resolve(null)
          })
      })
    })

    const ids = await Promise.all(improvementCreationPromises)

    return ids
  }

  const createCommitment = (commitment) => {
    const documentFile = commitment.documentFile
    delete commitment.documentFile
    axios
      .post('/graphql', {
        query: addCommitment,
        variables: { data: commitment },
      })
      .then(({data}) => {
        if (data.errors) throw new Error(data.errors[0].message)
        const newCommitmentId = data.data.insertOneCommitment._id

        // Upload document to AWS and then update the commitment in MongoDB with the AWS document key
        if (documentFile?.name) {
          uploadCommitmentFile(documentFile, "documents", newCommitmentId)
        }

        createBehaviourEvaluationEvent(commitment, newCommitmentId)
      })
      .catch((error) => {
        console.log(error)
        showErrorMessage(i18n.t('message.commitment_error'))
      })
  }

  const createCase = (caseData) => {
    const documentFile = caseData.documentFile
    delete caseData.documentFile

    return axios
      .post('/graphql', {
        query: addImprovement,
        variables: { data: caseData },
      })
      .then(({data}) => {
        if (data.errors) throw new Error(data.errors[0].message)
          
        // Upload document to AWS and then update the commitment in MongoDB with the AWS document key
        if (documentFile?.name) {
          const newImprovementId = data.data.insertOneImprovement._id
          uploadImprovementFile(documentFile, "documents", newImprovementId)
        }
      })
      .catch((error) => {
        console.log(error)
        showErrorMessage(i18n.t('message.commitment_error'))
      })
  }

  const uploadCommitmentFile = (file, folder, commitmentId) => {
    const destinationFolder = `${client.$oid}/${folder}`
    singleUpload(file, destinationFolder)
      .then((key) => updateCommitmentWithKey(commitmentId, key))
      .catch((err) => console.log(err))
  }

  const updateCommitmentWithKey = (commitmentId, key) => {
    axios
      .post('/graphql', {
        query: updateCommitment,
        variables: {
          query: { _id: commitmentId },
          data: { imageKey: key },
        },
      })
      .then(() => {})
      .catch((error) => {
        console.log(error)
        showErrorMessage(i18n.t('message.improvement_update_error'))
      })
  }

  const createBehaviourEvaluationEvent = (commitment, newCommitmentId) => {
    const endDate = new Date(commitment.dueDate)
    endDate.setHours(13, 0, 0, 0)

    const payload = {
      client_id: commitment.client_id,
      attendee: commitment.assignee,
      commitment: { link: newCommitmentId },
      title: commitment.title,
      start: commitment.dueDate,
      end: endDate,
      extendedProps: { calendar: 'Pendientes' },
      isBehaviourEvaluation: true
    }

    axios
      .post('/graphql', {
        query: addEvent,
        variables: { data: payload },
      })
      .then(({data}) => {
        if (data.errors) throw new Error(data.errors[0].message)
      })
      .catch((error) => {
        console.log(error)
        showErrorMessage(i18n.t('message.event_create_error'))
      })
  }

  const openDocument = (key) => {
    if (!key) {
      showErrorMessage(i18n.t('message.document_not_available'))
      return
    }
    const url = `https://${endpoints.aws_bucket}.s3.amazonaws.com/${key}`
    window.open(url)
  }

  const openImage = async (imageKey) => {
    let w = window.open("");
    w.document.write(`<h2>${i18n.t('message.loading_image')}</h2>`);
    try {
      const imgFromAWS = await getFile(endpoints.aws_bucket, imageKey)
      let image = new Image();
      image.src = imgFromAWS;

      w.document.body.innerHTML = "";
      w.document.write(image.outerHTML);
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.err_open_image'))
    }
  }

  const getCrimesForDropDown = () => {
    get('crimes').then(response => {
      if (response) crimes.value = response
      else if (localStorage.crimes) crimes.value = JSON.parse(localStorage.crimes)
    })
    store
      .dispatch('workerStoreModule/getLocationsForDropDown', { query: getCrimes, variables: { ...varGeneral, limit: 20000, sortBy: "NAME_ASC" } })
      .then(response => {
        if (response.data.errors) throw new Error(response.data.errors[0].message)
        crimes.value = response.data?.data?.crimes?.map(({ name, _id }) => ({ title: name, value: _id })) || []
        set('crimes', crimes.value).then(() => {}).catch(error => console.log(error))
      })
      .catch((error) => {
        console.log(error)
        get('crimes').then(response => {
          if (!response && !localStorage.crimes) handleError({ isStorageError: true })
        })
      })
  }

  // const getPrivilegesForDropDown = () => {
  //   if (localStorage.privileges) privileges.value = JSON.parse(localStorage.privileges)
  //   store
  //     .dispatch('processStoreModule/getPrivilegesForDropDown', { query: privilegesQuery, variables: { ...varGeneral, limit: 20000, sortBy: "NAME_ASC" } })
  //     .then(response => {
  //       if (response.data.errors) throw new Error(response.data.errors[0].message)
  //       privileges.value = response.data?.data?.privileges?.map(({ name, value }) => ({ title: name, value })) || []
  //       localStorage.privileges = JSON.stringify(privileges.value)
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //       if (!localStorage.privileges) handleError({ isStorageError: true })
  //     })
  // }

  function getPrivilegesForWorker() {
    let defaultPrivilege = []
    if (role === 'supervisor') {
      defaultPrivilege = [{ value: 'employee', title: i18n.t('userRoles.Employee') }]
    } else {
      defaultPrivilege = [
        { value: 'consultant', title: i18n.t('userRoles.Consultant') },
        { value: 'internalAuditor', title: i18n.t('userRoles.InternalAuditor') },
        { value: 'externalAuditor', title: i18n.t('userRoles.ExternalAuditor') },
        { value: 'supervisor', title: i18n.t('userRoles.Supervisor') },
        { value: 'employee', title: i18n.t('userRoles.Employee') },
      ]
    }
    workerPrivileges.value = defaultPrivilege
  }

  return {
    getRolesForDropDown,
    roles,
    getWorkersTotalForDropDown,
    workersTotal,
    workersTotalNotMapped,
    getWorkersSupervisedForDropDown,
    workersSupervised,
    workersSupervisedNotMapped,
    getSupervisorsForDropDown,
    supervisors,
    supervisorsOnlyNames,
    // getPrivilegesForDropDown,
    privileges,
    getProcessesForDropDown,
    processes,
    getLocationsForDropDown,
    locations,
    getCrimesForDropDown,
    crimes,
    getPrivilegesForWorker,
    workerPrivileges,
    getMotivesForDropDown,
    motives,
    getMetadataForDropDown,
    metadata,
    metadataNotMapped,
    metadataFilterOptions,
    metadataTypeSelect,
    metadataTypeSelectNotMapped,
    handleError,
    checkStorageData,
    updateStorageData,
    fetchAndStoreEvents,
    storeDataToUpload,
    formatDate,
    createCommitment,
    updateConfirmationWithKey,
    createImprovements,
    improvements,
    getImprovementsForDropDown,
    commitments,
    getCommitmentsForDropDown,
    openDocument,
    openImage,
    createCase,
  }
}